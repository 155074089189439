import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import parse from "html-react-parser"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { makeStyles } from "@material-ui/styles"
import { Typography } from "@material-ui/core"

import Link from "../../Link"
import Icon from "./Icon"

import { setEdition } from "../../../app/editionSlice"

import styles from "./styles"
import { isBrowser } from "../../../helpers"

const useStyles = makeStyles(theme => styles(theme))

export default function ArticleCard({
  slug,
  link,
  backgroundImage,
  readingTime,
  title,
  featured,
  colour,
  texture,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clickedEdition = useSelector(state => state.edition)

  useEffect(() => {
    if (clickedEdition === slug) {
      if (isBrowser) {
        dispatch(setEdition(""))
      }
    }
  }, [])

  return (
    <Link
      className={`article-card ${
        classes.articleCard
      } article-card-${slug} ${colour}-accent ${texture ? "" : "uncreased"}`}
      to={link}
      onClick={() => dispatch(setEdition(slug))}
    >
      <Image src={backgroundImage.localFile} alt={title} />
      {featured && <span className="featured">featured</span>}
      <span className="content">
        {readingTime !== false && (
          <span className="read-time">
            <Icon /> {readingTime} min read
          </span>
        )}
        <Typography
          component={"span"}
          variant={"h6"}
          data-override-color={"uow-white"}
        >
          {parse(title !== null ? title : "")}
        </Typography>
      </span>
    </Link>
  )
}

const Image = ({ src, alt }) => {
  const image = getImage(src)
  const data = useStaticQuery(graphql`
    query defaultImage {
      wp {
        themeOptions {
          ThemeOptions {
            defaultImages {
              postFeaturedImage {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const defaultImage = data.wp.themeOptions.ThemeOptions.defaultImages

  return (
    <figure
      style={{
        margin: 0,
        position: "absolute",
        inset: "0 0 0 0",
        objectFit: "cover",
        objectPosition: "center",
        overflow: "hidden",
      }}
    >
      {image ? (
        <GatsbyImage
          image={image}
          layout="fullWidth"
          transformOptions={{ fit: "cover", cropFocus: "center" }}
          placeholder="blurred"
          style={{ height: "100%", width: "100%" }}
          alt={alt ?? ""}
        />
      ) : (
        <img
          src={
            src.publicURL ?? defaultImage.postFeaturedImage.localFile.publicURL
          }
          alt={alt ?? ""}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      )}
    </figure>
  )
}
