import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MagazineListing from "../components/MagazineListing"

class Edition extends Component {
  render() {
    const { name } = this.props.data.wpEdition

    this.props.pageContext.pageTitle = name
    this.props.pageContext.edition = this.props.data.wpEdition

    return (
      <Layout
        meta={{
          ...this.props.data.wpEdition.Meta,
          ogImage: this.props.data.wpEdition.Editions.featuredImage.localFile
            .childImageSharp.fixed.src,
          twitterImage: this.props.data.wpEdition.Editions.featuredImage
            .localFile.childImageSharp.fixed.src,
        }}
        path={this.props.pageContext.pagePath}
        title={name}
        themeOptions={this.props.data.wp.themeOptions}
        pageColour={this.props.data.wpEdition.acfPageColour.pageColour}
      >
        <MagazineListing
          articles={this.props.data.wpEdition.Editions.articles}
          colour={this.props.data.wpEdition.acfPageColour.pageColour}
          name={this.props.data.wpEdition.name}
          personas={this.props.data.allWpPersona}
          editionSlug={this.props.data.wpEdition.slug}
        />
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpEdition(id: { eq: $id }) {
      id
      name
      slug
      Editions {
        articles {
          ... on WpMagazine {
            id
            title
            uri
            date
            slug
            Magazines {
              readingTime
              featured
              featuredImage {
                sourceUrl
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      formats: [JPG, WEBP, AVIF]
                    )
                  }
                }
              }
              personaTags {
                slug
              }
            }
            acfPageColour {
              pageColour
            }
          }
        }
        featuredImage {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              fixed(width: 300, height: 200) {
                src
              }
            }
          }
        }
      }
      acfPageColour {
        pageColour
      }
    }
    allWpPersona {
      edges {
        node {
          id
          slug
          title
          personaTags {
            nodes {
              slug
            }
          }
          persona {
            icon {
              localFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Edition
