import texture from "../../../images/cover-texture.png"

const styles = theme => ({
  articleCard: {
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderLeft: "8px solid var(--page-accent)",
    borderTop: "8px solid var(--page-accent)",
    boxShadow: "0px 24px 32px rgba(24,44,68,.28)",
    textDecoration: "none",
    position: "relative",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: "url(" + texture + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      zIndex: 1,
    },
    "&.uncreased:before": {
      backgroundImage: "none",
    },
    "& .featured": {
      position: "absolute",
      borderLeft: "30px solid var(--page-accent)",
      paddingLeft: 16,
      fontSize: 19,
      color: "var(--uow-white)",
      lineHeight: "24px",
      fontFamily: "var(--uow-header-font)",
      fontWeight: "bold",
      top: 30,
      left: 0,
      zIndex: 3,
      "&:before": {
        content: '""',
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "12px 10px 0 0",
        borderColor: "var(--page-accent) transparent transparent transparent",
        position: "absolute",
        top: 0,
        left: 0,
      },
      "&:after": {
        content: '""',
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "12px 0 0 10px",
        borderColor: "transparent transparent transparent var(--page-accent)",
        position: "absolute",
        bottom: 0,
        left: 0,
      },
    },
    "& .content": {
      transition: "padding-bottom .3s ease-in-out",
      padding: "0 24px 24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      minHeight: 335,
      position: "relative",
      zIndex: 2,
      background:
        "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.28) 35.52%, rgba(0,0,0,0.4) 100%)",
      [theme.breakpoints.up("md")]: {
        padding: "0 40px 40px",
      },
      "&:before": {
        content: '""',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: "absolute",
        backgroundColor: "var(--page-accent)",
        opacity: 0,
        transition: "opacity .7s ease-in-out",
      },
      "& span": {
        position: "relative",
        zIndex: 1,
        "&.read-time": {
          marginBottom: 16,
          color: "var(--uow-white)",
          fontFamily: "var(--body-font)",
          fontSize: 13.5,
          letterSpacing: "0.152em",
          textTransform: "uppercase",
          display: "block",
          "& svg": {
            marginRight: 5,
          },
        },
      },
    },
    "&.uncreased .content": {
      background:
        "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 35.52%, rgba(0,0,0,0.3) 100%)",
    },
    "&:hover": {
      "& .content": {
        paddingBottom: 48,
        [theme.breakpoints.up("md")]: {
          paddingBottom: 80,
        },
        "&:before": {
          opacity: 0.7,
        },
      },
    },
  },
})

export default styles
