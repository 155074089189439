import React, { useEffect, useState } from "react"
import { useScrollRestoration } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { sort } from "fast-sort"
import Typography from "@material-ui/core/Typography"

import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/styles"

import Cookies from "cookies-js"

import LifeGrid from "../Reusable/LifeGrid"
import ArticleCard from "../Reusable/ArticleCard"

import styles from "./styles"

import { setPersona } from "../../app/personaSlice"

export default function MagazineListing({
  articles,
  name,
  colour,
  personas,
  editionSlug,
}) {
  const scrollRestoration = useScrollRestoration(`magazine-listing`)
  const useStyles = makeStyles(theme => styles(theme))
  const classes = useStyles()
  const [showMore, setShowMore] = useState(true)

  const selectedPersona = useSelector(state => state.persona)

  const dispatch = useDispatch()
  const clickedEdition = useSelector(state => state.edition)
  const [showSavePersona, setShowSavePersona] = useState(false)
  const [showPersonaSaved, setShowPersonaSaved] = useState(false)
  const selectedPersonaData = personas.edges.find(
    edge => edge.node.slug === selectedPersona.slug
  )

  const selectedPersonaTags = selectedPersonaData
    ? selectedPersonaData.node.personaTags.nodes.map(node => node.slug)
    : []

  const isArticlePersonalized = article => {
    if (!article.Magazines.personaTags) return 0
    return !!article.Magazines.personaTags
      .map(node => node.slug)
      .find(tag => selectedPersonaTags.includes(tag))
  }

  const matchingTags = article => {
    if (!article.Magazines.personaTags) return -1

    const tags = article.Magazines.personaTags
      .map(node => node.slug)
      .filter(tag => selectedPersonaTags.includes(tag))

    if (!tags.length) return -1
    return tags.length
  }

  function onCheckSavePersona(e) {
    // Save a persona cookie
    if (typeof window.Optanon !== "undefined") {
      window.Optanon.UpdateConsent("Category", "C0003:1")
    }
    writePersonaCookie(selectedPersona.slug)
    setShowSavePersona(false)
    setShowPersonaSaved(true)
    setTimeout(() => setShowPersonaSaved(false), 3000)
  }

  const maxArticlesInGrid = 7

  const articlesSorted = sort(articles).desc([
    article => isArticlePersonalized(article),
    article => matchingTags(article),
    article => !!article.Magazines.featured,
  ])

  useEffect(() => {
    const indexOfScrollToArticle = articlesSorted?.findIndex(
      article => article.slug === clickedEdition
    )

    if (indexOfScrollToArticle >= maxArticlesInGrid - 2) {
      setShowMore(true)
    } else {
      setShowMore(false)
    }
  }, [])

  // The personaName needs passing in otherwise the state may not have been updated yet
  function writePersonaCookie(personaName) {
    const newCookieValue = { slug: personaName, by: "user" }
    Cookies.set("persona", JSON.stringify(newCookieValue), {
      expires: 365 * 86400,
    })
  }

  function permissionToWriteCookies() {
    return new Promise(resolve => {
      if (typeof window === "undefined") {
        resolve(false)
      }

      setTimeout(function checkConsentsDefined() {
        if (typeof window.OptanonActiveGroups !== "undefined") {
          resolve(window.OptanonActiveGroups.search("C0003") !== -1)
        } else {
          setTimeout(checkConsentsDefined, 100)
        }
      }, 100)
    })
  }

  async function onPersonaSelect(e) {
    const personaName = e.target.value.toLowerCase()
    dispatch(setPersona(personaName))

    let showSave = await shouldShowPersonaSave(personaName)
    setShowSavePersona(showSave)
    if (!showSave) {
      writePersonaCookie(personaName)
    }
  }

  async function shouldShowPersonaSave(personaName) {
    if (personaName === "default") {
      return false
    }

    // they have chosen a persona
    if (await permissionToWriteCookies()) {
      // We may write cookies
      return false
    } else {
      // The user has not globally consented to functional cookies
      return true
    }
  }

  useEffect(() => {
    async function check() {
      const showSave = await shouldShowPersonaSave(selectedPersona.slug)
      setShowSavePersona(showSave)
      if (!showSave) {
        // On first visit if the user has opted in to cookies, we can persist the persona in the cookie
        writePersonaCookie(selectedPersona.slug)
      }
    }
    check()
  }, [])

  return (
    <section
      className={`magazine-listing ${classes.magazineListing} ${colour}-accent`}
      {...scrollRestoration}
    >
      <LifeGrid color={colour} />
      <div className="listing-title">
        <Typography component="h1" variant="h1">
          WLV Life
        </Typography>
        <Typography component="h2" variant="h2">
          Edition {name}
        </Typography>
      </div>
      <div className="filter">
        <p>Sort Stories By</p>
        <Select value={selectedPersona.slug} onChange={e => onPersonaSelect(e)}>
          <MenuItem value="default" disabled>
            <ListItemIcon>Select</ListItemIcon>
          </MenuItem>
          {personas.edges.map(persona => (
            <MenuItem key={persona.node.id} value={persona.node.slug}>
              <ListItemIcon>
                <img
                  src={
                    persona.node.persona.icon?.localFile?.childImageSharp.fixed
                      .src
                  }
                />
              </ListItemIcon>
              <ListItemText>{persona.node.title}</ListItemText>
            </MenuItem>
          ))}
        </Select>
        {showSavePersona && (
          <div className="cookie-set">
            <label className="check-container">
              Save for future visits{" "}
              <small>
                (this action will opt you in to storage of functional cookies)
              </small>
              <input type="checkbox" onChange={e => onCheckSavePersona(e)} />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
        {showPersonaSaved && <div className="cookie-set">Saved!</div>}
      </div>
      <div className="listing-grid first">
        {articlesSorted?.slice(0, maxArticlesInGrid).map(article => (
          <ArticleCard
            slug={article.slug}
            key={article.id}
            link={article.uri}
            backgroundImage={article.Magazines.featuredImage}
            readingTime={article.Magazines.readingTime}
            title={article.title}
            colour={article.acfPageColour.pageColour}
            featured={article.Magazines.featured}
            texture={editionSlug < 2024 ? true : false}
          />
        ))}
        {articles?.length > maxArticlesInGrid && (
          <button
            className="show-more"
            onClick={() => setShowMore(true)}
            data-show={!showMore}
          >
            <span>Load more</span>
          </button>
        )}
      </div>
      {showMore && (
        <div className="listing-grid" data-show={showMore}>
          {articlesSorted
            ?.slice(maxArticlesInGrid, articles.length)
            .map(article => (
              <ArticleCard
                slug={article.slug}
                key={article.id}
                link={article.uri}
                backgroundImage={article.Magazines.featuredImage}
                readingTime={article.Magazines.readingTime}
                title={article.title}
                colour={article.acfPageColour.pageColour}
                featured={article.Magazines.featured}
                texture={editionSlug < 2024 ? true : false}
              />
            ))}
        </div>
      )}
    </section>
  )
}
