const styles = theme => ({
  // eslint-disable-line
  magazineListing: {
    position: "relative",
    backgroundColor: "var(--page-accent)",
    minHeight: 700,
    "& .listing-title": {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 255px)",
        display: "inline-block",
        verticalAlign: "top",
      },
      "& h1": {
        zIndex: 2,
        marginBottom: 0,
        marginTop: 24,
        position: "relative",
        "-webkit-text-stroke-width": "1px",
        "-webkit-text-stroke-color": "var(--uow-white)",
        color: "transparent !important",
      },
      "& h2": {
        zIndex: 2,
        marginBottom: 10,
        position: "relative",
        fontWeight: "normal !important",
      },
    },
    "& .filter": {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "255px",
        display: "inline-block",
        verticalAlign: "top",
      },
      "& > p": {
        fontSize: 12,
        letterSpacing: "0.088em",
        fontWeight: "bold",
        textTransform: "uppercase",
        lineHeight: 1,
        marginBottom: 10,
        marginTop: 20,
        [theme.breakpoints.up("md")]: {
          textAlign: "right",
          fontSize: 16,
        },
      },
      "& .MuiInputBase-root": {
        width: "100%",
        marginBottom: 20,
        "&:after": {
          display: "none !important",
        },
        "& .MuiSelect-root": {
          borderRadius: 4,
          border: "2px solid var(--uow-royal-blue)",
          padding: 10,
          paddingRight: 36,
          "& .MuiListItemIcon-root": {
            display: "inline-block",
            verticalAlign: "middle",
            minWidth: 32,
            width: 32,
            marginRight: 16,
            fontSize: 16,
            color: "var(--uow-royal-blue)",
            letterSpacing: "0.088em",
            lineHeight: "32px",
            height: 32,
            "&:after": {
              content: "''",
              display: "block",
              width: 12,
              height: 12,
              borderLeft: "3px solid var(--uow-royal-blue)",
              borderBottom: "3px solid var(--uow-royal-blue)",
              borderRadius: 2,
              transform: "rotate(-45deg)",
              top: 16,
              right: 14,
              position: "absolute",
            },
          },
          "& .MuiListItemText-root": {
            display: "inline-block",
            marginTop: 0,
            marginBottom: 0,
            verticalAlign: "middle",
            lineHeight: 1,
            "& span.MuiTypography-body1": {
              fontSize: 16,
              lineHeight: 1,
              letterSpacing: "0.088em",
              color: "var(--uow-royal-blue)",
            },
          },
        },
        "& svg": {
          display: "none",
        },
      },
      "& .cookie-set": {
        "& .check-container": {
          display: "block",
          position: "relative",
          paddingLeft: 42,
          marginBottom: 35,
          cursor: "pointer",
          userSelect: "none",
          fontSize: 14,
          fontWeight: "bold",
          letterSpacing: "0.032em",
          lineHeight: 1.2,
          color: "var(--uow-royal-blue)",
          "& small": {
            display: "block",
            lineHeight: 1.2,
            fontSize: 10,
            fontWeight: "normal",
          },
          "& input": {
            position: "absolute",
            opacity: 0,
            cursor: "pointer",
            height: 0,
            width: 0,
            "&:checked ~ .checkmark": {
              backgroundColor: "var(--uow-royal-blue)",
              "&:after": {
                display: "block",
              },
            },
          },
          "& .checkmark": {
            position: "absolute",
            top: 0,
            left: 0,
            height: 25,
            width: 25,
            border: "2px solid var(--uow-royal-blue)",
            borderRadius: 4,
            "&:after": {
              content: "''",
              position: "absolute",
              display: "none",
              left: 6,
              top: 1,
              width: 9,
              height: 15,
              border: "solid white",
              borderWidth: "0 3px 3px 0",
              transform: "rotate(45deg)",
            },
          },
          "&:hover input ~ .checkmark": {
            backgroundColor: "rgba(0,0,0,0.1)",
          },
        },
      },
    },
    "& iframe": {
      border: "none",
      position: "relative",
      zIndex: 2,
      marginBottom: 60,
    },
    "& .life-grid": {
      content: '""',
      display: "block",
      position: "absolute",
      zIndex: 0,
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
      top: -100,
      height: 600,
      opacity: 0.33,
      mixBlendMode: "multiply",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      [theme.breakpoints.up("md")]: {
        top: -120,
      },
    },
    "& .listing-grid": {
      position: "relative",
      zIndex: 10,
      display: "block",
      width: "calc(100% + 24px)",
      margin: "0 -12px",
      [theme.breakpoints.up("md")]: {
        width: "83.333%",
        margin: "0 auto",
      },
      "& .article-card": {
        float: "left",
        width: "calc(100% - 24px)",
        margin: "0 12px 24px",
        [theme.breakpoints.up("md")]: {
          width: "calc(50% - 24px)",
        },
      },
      "&.first": {
        "& .article-card": {
          "&:nth-of-type(1)": {
            [theme.breakpoints.up("md")]: {
              width: "calc(40% - 24px)",
            },
            "& .content": {
              minHeight: 520,
              [theme.breakpoints.up("md")]: {
                minHeight: 702,
              },
            },
          },
          [theme.breakpoints.up("md")]: {
            "&:nth-of-type(2), &:nth-of-type(3)": {
              width: "calc(60% - 24px)",
            },
          },
        },
      },
      "&:after": {
        clear: "both",
        display: "table",
        content: '""',
      },
      "& .show-more": {
        position: "absolute",
        bottom: 150,
        left: "50%",
        width: "auto",
        transform: "translateX(-50%)",
        fontSize: 16,
        lineHeight: 1,
        letterSpacing: "0.2em",
        color: "var(--uow-royal-blue)",
        fontFamily: "var(--uow-body-font)",
        textTransform: "uppercase",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        zIndex: 10,
        '&[data-show="false"]': {
          display: "none",
        },
        "& span": {
          position: "relative",
        },
        "&:before": {
          top: -200,
          bottom: -150,
          left: "50%",
          transform: "translateX(-50%)",
          width: "100vw",
          content: '""',
          display: "block",
          background:
            "linear-gradient(rgba(0,0,0,0) 0%, var(--page-accent) 75%)",
          position: "absolute",
          zIndex: 0,
        },
      },
      "&[data-show]": {
        opacity: 0,
        height: 0,
        display: "block",
        transition: "opacity .3s ease-in-out",
      },
      '&[data-show="true"]': {
        opacity: 1,
        display: "block",
        height: "auto",
        paddingBottom: 150,
      },
    },
  },
})

export default styles
